<template>
  <v-card elevation="0">
    <v-card-text class="pt-0">
      <v-alert type="info" dense text>
        Os planos são as formas como você pode vender sua filiação. Você pode
        criar planos com diferentes durações e preços.
      </v-alert>
      <v-alert type="warning" dense text v-if="membership.id">
        Alterar um planos já existente não afetará a validade das filiações
        ativas. O novo preço e duração serão aplicados apenas nas próximas
        compras.
      </v-alert>
      <v-form @submit.prevent v-model="valid" class="d-flex flex-column gap-2">
        <v-card
          outlined
          class="rounded-lg pa-2 px-4"
          v-for="(v, index) in val"
          :key="index"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="v.name"
                label="Título (opcional)"
                :placeholder="`Plano ${$options.filters.membershipDuration(v)}`"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                :items="validationType"
                v-model="v.validationType"
                label="Tipo de validade"
                outlined
                dense
                :messages="[
                  v.validationType == 'DATE'
                    ? 'Data fixa de vencimento'
                    : 'Data de vencimento a partir da compra',
                ]"
              />
              <template v-if="v.validationType == 'DATE'">
                <v-text-field
                  v-model="v.endDate"
                  label="Data de expiração"
                  type="date"
                  :rules="[(v) => !!v || 'Data obrigatória']"
                  outlined
                  dense
                  hide-details="auto"
                />
              </template>
              <template v-if="v.validationType == 'PERIOD'">
                <div class="d-flex">
                  <v-text-field
                  class="flex-grow-0 flex-shrink-0"
                    v-model="v.duration"
                    outlined
                    dense
                    type="number"
                    min="1"
                    :rules="[
                      (v) => !!v || 'Duração é obrigatória',
                      (v) => v > 0 || 'Duração deve ser maior que 0',
                    ]"
                    hide-details="auto"
                  />
                  <v-select
                    class="ml-2 flex-grow-1"
                    :items="durationType[index]"
                    v-model="v.durationType"
                    outlined
                    dense
                    hide-details
                  />
                </div>
              </template>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="d-flex gap-2">
                <v-text-field
                  v-model="v.price"
                  :rules="[
                    (va) => !!va || 'Preço é obrigatório',
                    (va) => va >= 3 || 'Preço deve ser maior que 3',
                  ]"
                  dense
                  prefix="R$"
                  label="Preço"
                  type="number"
                  step="0.01"
                  outlined
                  min="3"
                ></v-text-field>
                <v-btn v-if="!v.id" icon @click="removePlan(index)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <!-- <v-checkbox
                v-if="v.validationType !== 'DATE'"
                v-model="v.autoRenew"
                label="Renovação automática"
                dense
                hide-details
              ></v-checkbox> -->
              <v-checkbox
                v-model="v.onlineSale"
                label="Mostrar Online"
                dense
                hide-details
              ></v-checkbox>
              <!-- <v-switch
                v-model="v.active"
                label="Ativo"
                dense
                hide-details
              ></v-switch> -->
            </v-col>
          </v-row>
        </v-card>
        <v-btn
          :small="!!val.length"
          :large="!val.length"
          block
          @click="addPlan"
          elevation="0"
        >
          <v-icon left>mdi-plus</v-icon>
          Adicionar plano
        </v-btn>
      </v-form>
      <v-spacer />
      <div class="d-flex justify-space-between align-center mt-3">
        <v-btn text @click="$emit('prev')"> Voltar </v-btn>
        <v-btn
          color="primary"
          :disabled="!isValid || !valid"
          @click="$emit('next')"
        >
          Próximo
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
const defaultPlan = () => ({
  name: null,
  price: 0,
  validationType: "PERIOD",
  endDate: null,
  duration: 1,
  durationType: "MONTH",
  autoRenew: true,
  onlineSale: true,
});

export default {
  data: () => ({
    valid: false,
    validationType: [
      { value: "DATE", text: "Dia fixo" },
      { value: "PERIOD", text: "Período" },
    ],
  }),
  methods: {
    removePlan(index) {
      this.val = this.val.filter((_, i) => i !== index);
    },
    addPlan() {
      this.val = [...this.val, defaultPlan()];
    },
  },
  computed: {
    durationType() {
      return this.val.map((v) => {
        const isPlural = v.duration != 1;

        return [
          { value: "DAYS", text: isPlural ? "dias" : "dia" },
          { value: "MONTH", text: isPlural ? "meses" : "mês" },
          { value: "YEAR", text: isPlural ? "anos" : "ano" },
        ];
      });
    },
    isValid() {
      if (this.value.length === 0) return false;
      return true;
    },
    val: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    membership: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
