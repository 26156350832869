<template>
  <v-card elevation="0">
    <v-card-text class="pt-0">
      <v-alert type="info" dense text>
        No momento da filiação, os dados <b>nome</b>, <b>Whatsapp</b>,
        <b>email</b> e <b>documento</b> do cliente já serão compartilhados com a
        organização.
      </v-alert>
      <v-card
        v-if="!val.length"
        outlined
        class="rounded-lg d-flex flex-column pa-4 align-center justify-center"
      >
        <h6 class="mb-0">Nenhum campo adicionado</h6>
        <p class="mb-0">
          Adicione campos para coletar informações extras dos clientes no
          momento da filiação.
        </p>
      </v-card>
      <form-editor
        :value="val"
        @input="($val) => (val = $val)"
        @valid="($val) => (valid = $val)"
      />

      <div class="d-flex justify-space-between align-center mt-3">
        <v-btn text @click="$emit('prev')"> Voltar </v-btn>
        <v-btn color="primary" @click="$emit('next')" :disabled="!valid">
          Próximo
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import formEditor from "../../../../global/form/formEditor.vue";
export default {
  components: { formEditor },
  data: () => ({
    valid: true,
  }),
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    membership: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
