<template>
  <v-dialog scrollable v-model="dialog" max-width="500" :persistent="loading">
    <v-card>
      <v-card-text class="pt-3">
        <v-form>
          <div class="d-flex justify-center mb-4">
            <v-hover v-slot="{ hover }">
              <v-avatar size="100" color="grey">
                <v-img :src="partner.image || fileUrl">
                  <v-fade-transition leave-absolute>
                    <v-avatar
                      v-if="hover || loading || (!partner.image && !fileUrl)"
                      @click="changePhoto"
                      class="d-flex transition-fast-in-fast-out grey v-card--reveal text-h2 white--text justify-center align-center"
                      style="opacity: 0.8"
                      size="100"
                    >
                      <v-progress-circular indeterminate v-if="loading" />
                      <v-icon class="white--text" v-else>mdi-camera</v-icon>
                    </v-avatar>
                  </v-fade-transition>
                </v-img>
              </v-avatar>
            </v-hover>
            <input
              type="file"
              accept="image/*"
              ref="photoInput"
              @change="onPhotoChange"
              v-show="false"
            />
          </div>

          <!-- <div v-if="mapsImages.length">
            <v-slide-group show-arrows>
              <v-slide-item
                v-for="(image, n) in mapsImages"
                :key="n"
                v-slot="{ active, toggle }"
              >
                <div>
                  <v-avatar size="75px" :class="{ active: active }">
                    <v-img
                      :src="image"
                      height="75px"
                      width="75px"
                      @click="toggle"
                    />
                  </v-avatar>
                </div>
              </v-slide-item>
            </v-slide-group>
          </div> -->

          <place-search
            outlined
            label="Pesquise pelo nome ou endereço do parceiro"
            placeholder="Pesquise pelo nome para preencher automaticamente os campos"
            v-model="partner.address"
            @input:raw="importDetails"
            :detailsFields="[
              'formatted_phone_number',
              'website',
              'business_status',
              'url',
              // 'photos',
            ]"
          />
          <v-divider class="my-4" />

          <v-text-field
            v-model="partner.name"
            label="Nome"
            outlined
            dense
            :rules="[
              (v) => !!v || 'Nome é obrigatório',
              (v) =>
                (v && v.length <= 50) || 'Nome deve ter menos de 50 caracteres',
            ]"
          />
          <v-text-field
            v-model="partner.phone"
            label="Telefone"
            outlined
            dense
          />
          <v-text-field
            v-model="partner.website"
            label="Site/Rede Social"
            outlined
            dense
          />
          <v-textarea
            v-model="partner.description"
            label="Descrição do benefício"
            outlined
            dense
            :rules="[
              (v) => !!v || 'Descrição é obrigatória',
              (v) =>
                (v && v.length <= 500) ||
                'Descrição deve ter menos de 500 caracteres',
            ]"
            counter="500"
            rows="3"
          />
          <v-autocomplete
            v-model="partner.category"
            outlined
            dense
            label="Categoria"
            :items="categoryList"
            :search-input.sync="searchCategory"
            auto-select-first
            hide-details
            class="mb-3"
          ></v-autocomplete>
        </v-form>
        <v-alert v-if="error" type="error" class="mt-4">
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="close" :disabled="loading"> Fechar </v-btn>
        <v-spacer />
        <v-btn color="primary" @click="save" :loading="loading">
          {{ partner.id ? "Atualizar" : "Criar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MEMBERSHIP from "@/services/admin/membership";
import { mapGetters } from "vuex";
import PhoneInput from "../../../global/PhoneInput.vue";
import PlaceSearch from "../../../../views/global/PlaceSearch.vue";

function defaultPartner() {
  return {
    name: "",
    phone: "",
    description: "",
    website: "",
    category: null,
    image: null,
    address: null,
  };
}
export default {
  components: {
    PhoneInput,
    PlaceSearch,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      error: false,
      valid: false,
      hover: false,
      file: null,
      fileUrl: null,
      mapsImages: [],
      partner: defaultPartner(),
      searchCategory: "",
      autofill: {
        name: false,
        phone: false,
        website: false,
      },
    };
  },
  methods: {
    changePhoto() {
      this.$refs.photoInput.click();
    },
    onPhotoChange(e) {
      this.file = e.target.files[0];
      this.fileUrl = URL.createObjectURL(this.file);
    },
    importDetails(place) {
      if (place.business_status != "OPERATIONAL") return;
      this.partner.name = place.name;
      this.partner.phone = place.formatted_phone_number;
      this.partner.website = place.website || place.url;
      this.mapsImages = place.photos.map((p) => p.getUrl());
    },
    async save() {
      try {
        this.loading = true;
        this.error = false;
        var partner = null;
        if (this.partner.id) {
          var { partner } = await MEMBERSHIP.partner.update(
            this.selectedOrganization.id,
            this.partner
          );
        } else {
          var { partner } = await MEMBERSHIP.partner.create(
            this.selectedOrganization.id,
            this.partner
          );
        }
        await this.uploadFile(partner.id);
        this.$emit("success");
        this.close(true);
      } catch (e) {
        this.error = e.message || "Erro ao salvar parceiro";
      } finally {
        this.loading = false;
      }
    },
    async uploadFile(id) {
      if (!this.file) return;
      await MEMBERSHIP.partner.uploadPhoto(
        this.selectedOrganization.id,
        id,
        this.file
      );
    },
    open(data) {
      this.partner = Object.assign(defaultPartner(), data || {});
      this.dialog = true;
      this.file = null;
      this.fileUrl = null;
    },
    close(force = false) {
      if (this.loading && !force) return;
      this.dialog = false;
    },
    firthLetterUpper(str) {
      if (!str) return str;
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
  watch: {},

  computed: {
    ...mapGetters("organization", ["memberships", "selectedOrganization"]),
    categoryList() {
      const d = [
        "Academia",
        "Bares",
        "Beleza",
        "Casa",
        "Cursos",
        "Delivery",
        "Diversão",
        "Educação",
        "Esportes",
        "Estética",
        "Farmácia",
        "Gastronomia",
        "Hotelaria",
        "Lazer",
        "Moda",
        "Saúde",
        "Serviços",
        "Supermercado",
        "Turismo",
        this.partner.category,
        this.firthLetterUpper(this.searchCategory),
      ]
        .filter((v) => !!v)
        .filter(
          (v, i, a) =>
            a.findIndex((t) => t.toLowerCase() === v.toLowerCase()) === i
        );
      return d;
    },
  },
};
</script>

<style scoped>
.active {
  position: relative;
  z-index: 2;
}
.active::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: rgba(36, 41, 57, 0.9) !important;
  z-index: 1;
}
</style>
