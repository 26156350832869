<template>
  <v-card elevation="0">
    <div class="px-4 pb-4">
      <v-row>
        <v-col cols="12" sm="5" md="6">
          <v-card
            class="white--text d-flex justify-center"
            :style="{
              borderRadius: '10px',
              border: `3px solid ${membership.backgroundColor || '#000'}`,
              boxShadow: `0 0 10px  ${
                membership.backgroundColor || 'rgba(0,0,0,.2)'
              }`,
              background: `url('${membership.backgroundImg}'), ${
                membership.backgroundColor || '#000'
              }`,
              backgroundSize: 'cover',
            }"
          >
            <v-responsive
              :aspect-ratio="5.4 / 8.5"
              width="300px"
              style="background-color: rgba(0, 0, 0, 0.7)"
            >
              <div class="d-flex flex-column justify-space-between h-full pa-4">
                <div class="d-flex gap-4">
                  <h3 class="white--text text-center">{{ membership.name }}</h3>
                </div>
                <div
                  class="d-flex flex-column text-16 gap-2 pa-4 px-2 rounded-lg"
                  style="background: rgba(0, 0, 0, 0.9)"
                >
                  <div
                    class="d-flex gap-4"
                    v-for="(benefit, i) in benefits"
                    :key="i"
                  >
                    <v-icon color="yellow">mdi-star</v-icon>
                    <span>{{ benefit }}</span>
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <h5 class="white--text mb-0">
                    A partir de
                    <template v-if="priceStartIn.validationType == 'PERIOD'">
                      <strong>
                        {{
                          (priceStartIn.price /
                            (priceStartIn.duration *
                              periodMultiply[priceStartIn.durationType]))
                            | currency
                        }}
                        por mês
                      </strong>
                    </template>
                    <strong v-else>{{ priceStartIn.price | currency }} </strong>
                  </h5>
                  <p
                    class="mb-0"
                    v-if="priceStartIn.validationType == 'PERIOD'"
                  >
                    Plano {{ priceStartIn | membershipDuration }}
                  </p>
                  <p class="mb-0" v-else>
                    Plano valido até {{ priceStartIn.endDate | date }}
                  </p>
                </div>
              </div>
            </v-responsive>
          </v-card>
        </v-col>
        <v-col cols="12" sm="7" md="6">
          <h5 class="mb-3">Planos</h5>
          <div class="d-flex flex-column gap-2">
            <v-card
              v-for="(plan, i) in sortedPlans"
              :key="i"
              outlined
              class="pa-2 px-4 d-flex justify-space-between align-center"
            >
              <div>
                <span
                  class="text-overline"
                  style="line-height: 1rem"
                  v-if="plan.name"
                >
                  {{ plan.name }}
                </span>
                <h6 class="mb-0">{{ plan.price | currency }}</h6>
                <p class="mb-0" v-if="plan.validationType == 'PERIOD'">
                  Recorrência {{ plan | membershipDuration }}
                </p>
                <p class="mb-0" v-else>Válido até {{ plan.endDate | date }}</p>
              </div>
              <div>
                <v-tooltip v-if="plan.validationType == 'PERIOD'" left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-on="on" v-bind="attrs">
                      <v-icon>
                        mdi-sync{{ plan.autoRenew ? "" : "-off" }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{
                      plan.autoRenew
                        ? "Renovação automática"
                        : "Pagamento único"
                    }}
                  </span>
                </v-tooltip>
                <v-tooltip v-if="plan.validationType == 'PERIOD'" left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-on="on" v-bind="attrs">
                      <v-icon>
                        mdi-web{{ plan.onlineSale ? "" : "-off" }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{
                      plan.onlineSale ? "Mostrar online" : "Não mostrar online"
                    }}
                  </span>
                </v-tooltip>
              </div>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-alert type="error" v-if="error">
        {{ error }}
      </v-alert>

      <v-spacer />
      <div
        class="d-flex justify-space-between align-center mt-3"
        v-if="!success"
      >
        <v-btn :disabled="loading" text @click="$emit('prev')"> Voltar </v-btn>
        <v-btn :loading="loading" color="success" @click="save">
          {{ membership.id ? "Salvar" : "Criar" }}
        </v-btn>
      </div>
      <div class="d-flex justify-end align-center mt-3" v-else>
        <v-btn :loading="loading" text @click="close"> Fechar </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import MEMBERSHIP from "@/services/admin/membership";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data: () => ({
    success: false,
    loading: false,
    error: null,
    periodMultiply: {
      MONTH: 1,
      YEAR: 12,
      DAYS: 1 / 30,
    },
  }),
  methods: {
    async save() {
      try {
        this.loading = true;
        this.error = null;
        var membership = this.membership;
        if (membership.id) {
          await this.updateMembership();
        } else {
          membership = await this.createMembership();
        }
        this.success = true;

        await this.uploadCover(membership.id).catch(() => {
          throw {
            message:
              "Erro ao enviar imagem de capa, tente novamente editando esse plano",
          };
        });
      } catch (e) {
        this.error = e.message || "Erro ao criar plano";
      } finally {
        this.loading = false;
      }
    },
    async updateMembership() {
      await MEMBERSHIP.update(this.selectedOrganization.id, {
        ...this.membership,
        customFields: this.customFields,
      });
      for (let plan of this.plans) {
        if (plan.id)
          await MEMBERSHIP.updatePlan(
            this.selectedOrganization.id,
            this.membership.id,
            {
              ...plan,
              endDate: plan.endDate ? moment(plan.endDate).toISOString() : null,
            }
          );
        else
          await MEMBERSHIP.createPlan(
            this.selectedOrganization.id,
            this.membership.id,
            {
              ...plan,
              endDate: plan.endDate ? moment(plan.endDate).toISOString() : null,
            }
          );
      }
    },
    async createMembership() {
      
      const data = {
        ...this.membership,
        customFields: this.customFields,
        plans: this.plans.map((p) => ({
          ...p,
          endDate: p.endDate ? moment(p.endDate).toISOString() : null,
        })),
      };

      const { membership } = await MEMBERSHIP.create(
        this.selectedOrganization.id,
        data
      );
      return membership;
    },
    async uploadCover(membershipId) {
      const membership = this.membership;
      if (membership.backgroundFile)
        await MEMBERSHIP.cover(
          this.selectedOrganization.id,
          membershipId,
          membership.backgroundFile
        );
      else if (!membership.backgroundImg)
        await MEMBERSHIP.coverRemove(
          this.selectedOrganization.id,
          membershipId
        );
    },
    close() {
      this.$emit("close", this.success);
      this.success = false;
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),

    priceStartIn() {
      const { periodMultiply } = this;
      const period = this.plans.filter((p) => p.validationType === "PERIOD");
      if (period.length) {
        const periodMin = period.reduce(
          (acc, curr) => {
            const month = curr.duration * periodMultiply[curr.durationType];
            const price = curr.price / month;
            const monthAcc = acc.duration * periodMultiply[acc.durationType];
            const priceAcc = acc.price / monthAcc;
            return price < priceAcc ? curr : acc;
          },
          { price: Infinity, durationType: "MONTH", duration: 1 }
        );
        return periodMin;
      }
      return this.plans.reduce(
        (acc, curr) => {
          return curr.price < acc.price ? curr : acc;
        },
        { price: Infinity }
      );
    },
    benefits() {
      return this.membership.description
        ? this.membership.description.split("\n")
        : [];
    },
    sortedPlans() {
      return [...this.plans].sort((a, b) => a.price - b.price);
    },
  },

  props: {
    customFields: {
      type: Array,
      default: () => [],
    },
    membership: {
      type: Object,
      default: () => ({}),
    },
    plans: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
