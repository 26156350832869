<template>
  <v-text-field
    :label="options.title"
    :placeholder="options.description"
    :rules="options.required ? [(v) => !!v || 'Campo obrigatório'] : []"
    :value="val"
    @input="($val) => (val = $val)"
    outlined
    dense
    :readonly="readonly"
  />
</template>

<script>
export default {
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
