<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="700"
    :persistent="loading"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-text class="pa-0">
        <v-stepper v-model="step" class="elevation-0">
          <v-stepper-header>
            <v-stepper-step
              :complete="step > 1"
              :step="1"
              :editable="step <= 2"
            >
              Plano
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" :step="2">
              Identificação
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step >= 3" :step="3">
              Confirmação
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1" class="pa-0">
              <v-card-text class="pb-0">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-radio-group :value="membership?.id" class="mt-0 pt-0">
                      <v-card
                        v-for="ms in membershipsMapped"
                        :key="ms.id"
                        @click="selectMembership(ms)"
                        class="white--text mt-2"
                        :style="{
                          borderRadius: '10px',
                          border: `3px solid ${ms.backgroundColor || '#000'}`,
                          boxShadow: `0 0 10px  ${
                            ms.backgroundColor || 'rgba(0,0,0,.2)'
                          }`,
                          background: `url('${ms.backgroundImg}'), ${
                            ms.backgroundColor || '#000'
                          }`,
                          backgroundSize: 'cover',
                        }"
                      >
                        <div
                          class="d-flex flex-column justify-space-between h-full pa-4 gap-7"
                          style="background: rgba(0, 0, 0, 0.9)"
                        >
                          <div class="d-flex align-center gap-1">
                            <v-radio
                              dark
                              class="mb-0"
                              :value="ms.id"
                              @click="selectMembership(ms)"
                            />
                            <h5 class="mb-0 white--text">{{ ms.name }}</h5>
                          </div>
                          <div class="gap-1">
                            <template
                              v-if="ms.cheapestPlan.validationType === 'PERIOD'"
                            >
                              <p class="mb-0">
                                <i class="text-14">
                                  Plano
                                  {{ ms.cheapestPlan | membershipDuration }}
                                </i>
                              </p>
                              {{ ms.Plans.length > 1 ? "A partir de " : "" }}
                              <span class="font-weight-bold">
                                {{
                                  (ms.cheapestPlan.price /
                                    (ms.cheapestPlan.duration *
                                      periodMultiply[
                                        ms.cheapestPlan.durationType
                                      ]))
                                    | currency(true)
                                }}
                              </span>
                              por mês
                            </template>
                            <template v-else> </template>
                          </div>
                        </div>
                      </v-card>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="6">
                    <h6 v-if="membership">Selecione o plano</h6>
                    <v-radio-group :value="filiation.membershipPlanId">
                      <v-card
                        outlined
                        v-for="plan in membership?.Plans || []"
                        :key="plan.id"
                        class="pa-3 d-flex align-center justify-space-between mb-2"
                        @click="selectPlan(plan)"
                      >
                        <div class="d-flex gap-1">
                          <v-radio class="mb-0" :value="plan.id" />
                          <div>
                            <span
                              style="line-height: 1rem"
                              class="text-overline"
                            >
                              {{ plan.name }}
                            </span>
                            <h5 class="mb-0">
                              {{ plan.price | currency(true) }}
                            </h5>
                          </div>
                        </div>
                        <div class="text-right">
                          <template v-if="plan.validationType === 'PERIOD'">
                            Plano {{ plan | membershipDuration }}
                          </template>
                          <template v-else>
                            Válido até {{ plan.endDate | date }}
                          </template>
                        </div>
                      </v-card>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-stepper-content>

            <v-stepper-content step="2" class="pa-0">
              <v-card-text class="pb-0">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card
                      class="white--text mt-2"
                      :style="{
                        borderRadius: '10px',
                        border: `3px solid ${
                          membership?.backgroundColor || '#000'
                        }`,
                        boxShadow: `0 0 10px  ${
                          membership?.backgroundColor || 'rgba(0,0,0,.2)'
                        }`,
                        background: `url('${membership?.backgroundImg}'), ${
                          membership?.backgroundColor || '#000'
                        }`,
                        backgroundSize: 'cover',
                      }"
                    >
                      <div
                        class="d-flex flex-column justify-space-between h-full pa-4 gap-7"
                        style="background: rgba(0, 0, 0, 0.9)"
                      >
                        <div class="d-flex align-center justify-space-between">
                          <h5 class="mb-0 white--text">
                            {{ membership?.name }}
                          </h5>
                          <v-icon v-if="selectedPlan?.autoRenew" color="white">
                            mdi-sync-off
                          </v-icon>
                        </div>
                        <div class="d-flex flex-column gap-1">
                          <div
                            class="d-flex align-center gap-2"
                            v-for="(benefit, i) in membership?.benefits || []"
                            :key="i"
                          >
                            <v-icon color="yellow">mdi-star</v-icon>
                            {{ benefit }}
                          </div>
                        </div>
                        <div class="d-flex justify-space-between align-end">
                          <div>
                            <span
                              class="text-overline"
                              style="line-height: 1rem"
                            >
                              {{ selectedPlan?.name }}
                            </span>
                            <h5 class="white--text mb-0">
                              {{ selectedPlan?.price | currency(true) }}
                            </h5>
                          </div>
                          <h6
                            v-if="selectedPlan?.validationType === 'PERIOD'"
                            class="white--text mb-0"
                          >
                            {{ selectedPlan | membershipDuration }}
                          </h6>
                          <h6 v-else class="white--text mb-0">
                            Válido até {{ selectedPlan?.endDate | date }}
                          </h6>
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="6" align-self="center">
                    <phone-input
                      :phone="filiation.phone"
                      @update:phone="(e) => (filiation.phone = e)"
                      :ddi="filiation.ddi"
                      @update:ddi="(e) => (filiation.ddi = e)"
                      @valid="(v) => (valid = v)"
                      dense
                      outlined
                      label="WhatsApp do Filiado"
                      :disabled="loading"
                    />
                    <v-select
                      label="Forma de Pagamento"
                      hide-details="auto"
                      v-model="filiation.paymentType"
                      :items="paymentTypes"
                      dense
                      outlined
                    />
                    <v-alert
                      v-if="selectedPlan?.autoRenew"
                      class="mb-0 mt-4"
                      type="warning"
                      dense
                      border="left"
                      icon="mdi-sync-off"
                      text
                    >
                      A renovação automática não está disponível ao realizar o
                      pagamento direto com a organização.
                    </v-alert>
                  </v-col>
                </v-row>
                <v-alert v-if="error" type="error" dense border="left" text>
                  {{ error }}
                </v-alert>
              </v-card-text>
            </v-stepper-content>
            <v-stepper-content step="3" class="pa-0">
              <v-card-text>
                <membership-card
                  v-if="!!createdFiliation"
                  :value="createdFiliation.membershipFiliation"
                />
              </v-card-text>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-card-actions v-if="step == 1">
        <v-btn text @click="close" :disabled="loading">Cancelar</v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          :disabled="!filiation.membershipPlanId"
          text
          @click="step = 2"
        >
          Proximo
        </v-btn>
      </v-card-actions>
      <v-card-actions v-if="step == 2">
        <v-btn text @click="step = 1" :disabled="loading">voltar</v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          :disabled="!valid || !filiation.paymentType"
          :loading="loading"
          @click="save"
        >
          Vender
        </v-btn>
      </v-card-actions>
      <v-card-actions v-if="step == 3">
        <v-spacer />
        <v-btn color="primary" @click="close"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import membershipCheapestPlan from "@/utils/membershipCheapestPlan";
import PhoneInput from "../../../global/PhoneInput.vue";
import MEMBERSHIP from "@/services/admin/membership";
import MembershipCard from "@/components/global/MembershipCard.vue";

const defaultMembershipFiliation = () => ({
  membershipPlanId: null,
  phone: "",
  ddi: 55,
  paymentType: null,
});

export default {
  components: { PhoneInput, MembershipCard },
  data() {
    return {
      dialog: false,
      loading: false,
      error: false,
      valid: false,
      step: 1,
      createdFiliation: null,
      filiation: defaultMembershipFiliation(),
      membership: null,
      periodMultiply: {
        MONTH: 1,
        YEAR: 12,
        DAYS: 1 / 30,
      },
      paymentTypes: [
        { value: "PIX", text: "PIX" },
        { value: "DEBIT_CARD", text: "Cartão de Débito" },
        { value: "CREDIT_CARD", text: "Cartão de Crédito" },
        { value: "BANK_TRANSFER", text: "Transferência Bancária" },
        { value: "MONEY", text: "Dinheiro" },
      ],
    };
  },
  methods: {
    selectPlan(plan) {
      this.filiation.membershipPlanId = plan.id;
    },
    selectMembership(membership) {
      if (this.membership?.id === membership.id) return;
      this.membership = membership;
      this.filiation.membershipPlanId = membership.cheapestPlan?.id || null;
    },
    async save() {
      try {
        this.loading = true;
        this.error = false;

        const filiation = await MEMBERSHIP.filiation.create(
          this.selectedOrganization.id,
          this.filiation
        );
        this.$emit("success");
        this.step = 3;
        this.createdFiliation = filiation;
      } catch (e) {
        this.error = e.message || "Erro ao salvar filiação";
      } finally {
        this.loading = false;
      }
    },
    open() {
      this.createdFiliation = null;
      this.step = 1;
      this.filiation = defaultMembershipFiliation();
      this.selectMembership(this.membershipsMapped[0]);
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.dialog = false;
    },
  },

  computed: {
    ...mapGetters("organization", ["memberships", "selectedOrganization"]),
    membershipsMapped() {
      return this.memberships.map((m) => ({
        ...m,
        benefits: m.description.split("\n"),
        cheapestPlan: membershipCheapestPlan(m.Plans),
      }));
    },
    selectedPlan() {
      if (!this.membership) return null;
      return this.membership.Plans.find(
        (p) => p.id === this.filiation.membershipPlanId
      );
    },
  },
  filters: {},
};
</script>

<style></style>
