const periodMultiply = {
  MONTH: 1,
  YEAR: 12,
  DAYS: 1 / 30,
};

export default (plans) => {
  const period = plans.filter((p) => p.validationType === "PERIOD");
  if (period.length) {
    const periodMin = period.reduce(
      (acc, curr) => {
        const month = curr.duration * periodMultiply[curr.durationType];
        const price = curr.price / month;
        const monthAcc = acc.duration * periodMultiply[acc.durationType];
        const priceAcc = acc.price / monthAcc;
        return price < priceAcc ? curr : acc;
      },
      { price: Infinity, durationType: "MONTH", duration: 1 }
    );
    return periodMin;
  }
  return plans.reduce(
    (acc, curr) => {
      return curr.price < acc.price ? curr : acc;
    },
    { price: Infinity }
  );
};
