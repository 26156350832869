<template>
  <div>
    <div class="mb-2 d-flex flex-column gap-2">
      <v-card
        outlined
        class="pa-4"
        v-for="(item, i) in val"
        :key="i"
        :style="
          !valid[i] || editing === i
            ? `border-color: ${editing === i ? 'blue' : 'red'}`
            : ''
        "
      >
        <div class="d-flex justify-space-between mb-2">
          <b>
            Campo #{{ i + 1 }}
            <v-chip v-if="!valid[i]" color="error" label x-small>
              Inválido
            </v-chip>
          </b>
          <div>
            <v-btn icon small @click="toggleEditing(i)">
              <v-icon small>
                mdi-{{ editing === i ? "check" : "pencil" }}
              </v-icon>
            </v-btn>
            <v-btn icon small @click="removeComponent(i)">
              <v-icon small>mdi-trash-can-outline</v-icon>
            </v-btn>
          </div>
        </div>
        <component
          v-if="editing === i"
          :is="`${item.type}-editor`"
          :value="item.data"
          @input="($val) => setData(i, $val)"
          @valid="($val) => setValid(i, $val)"
        />
        <div v-else>
          <span
            v-if="editing !== i"
            class="text-overline mb-3"
            style="line-height: 1.2rem"
          >
            Pré-visualização
          </span>
          <component :is="item.type" :options="item.data" readonly />
        </div>
      </v-card>
    </div>
    <v-menu>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" block small v-bind="attrs" v-on="on">
          <v-icon>mdi-plus</v-icon>
          Adicionar campo
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="item in componentsType"
          :key="item.id"
          @click="addComponent(item)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import FormTextInput from "./modules/FormTextInput.vue";
import FormTextInputEditor from "./modules/editor/FormTextInput.vue";

import FormSelectInputEditor from "./modules/editor/FormSelectInput.vue";

export default {
  components: { FormTextInputEditor, FormSelectInputEditor, FormTextInput },
  data() {
    return {
      valid: new Array(this.value.length).fill(true),
      editing: false,
      componentsType: [
        {
          id: "textInput",
          title: "Texto",
          icon: "mdi-text",
          component: "form-text-input",
        },
        // {
        //   id: "selectInput",
        //   title: "Seleção da lista",
        //   icon: "mdi-format-list-checks",
        //   component: "form-select-input",
        // },
      ],
    };
  },
  methods: {
    setData(index, data) {
      this.val = this.val.map((item, i) => {
        if (i === index)
          return {
            ...item,
            data,
          };

        return item;
      });
    },
    setValid(index, valid) {
      this.valid[index] = valid;
      this.$emit(
        "valid",
        !this.valid.length || this.valid.every((item) => item)
      );
    },
    addComponent(item) {
      this.editing = this.val.length;
      this.valid = [...this.valid, false];
      this.val = [
        ...this.val,
        {
          type: item.component,
          data: {},
        },
      ];
    },
    removeComponent(index) {
      this.val = this.val.filter((_, i) => i !== index);
      this.valid.splice(index, 1);
      if (this.editing === index) this.editing = false;
      else if (this.editing > index) this.editing = this.editing - 1;
      this.$emit(
        "valid",
        !this.valid.length || this.valid.every((item) => item)
      );
    },
    toggleEditing(index) {
      if (this.editing === index) this.editing = false;
      else this.editing = index;
    },
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
