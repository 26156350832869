<template>
  <div class="mt-2">
    <v-card outlined :loading="loading" class="mb-2">
      <v-card-text class="d-flex flex-wrap justify-space-around px-8">
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">{{ members.length }}</h6>
          <span class="text-caption">Membros Ativos</span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">{{ highlight.withRegistration }}</h6>
          <span class="text-caption">Membros com cadastro</span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">{{ highlight.withoutRegistration }}</h6>
          <span class="text-caption">Membros sem cadastro</span>
        </div>
        <div class="text-center px-4">
          <h6 class="mt-2 mb-0">{{ highlight.nextValid | date }}</h6>
          <span class="text-caption">Próximo vencimento</span>
        </div>
      </v-card-text>
    </v-card>

    <div class="d-flex align-center mb-2 gap-2">
      <v-text-field
        label="Pesquisar"
        hide-details=""
        v-model="search"
        dense
        outlined
      />
      <v-btn color="primary" @click="addFiliation">
        <v-icon left>mdi-plus</v-icon>
        Novo
      </v-btn>
      <v-btn
        icon
        @click="getMembershipMembers"
        :loading="loading"
        :disabled="refreshDisabled"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="members"
      :loading="loading"
      :search="search"
      :items-per-page="10"
    >
      <template v-slot:item.name="{ item }">
        <base-avatar
          v-if="!!item.name"
          :src="item.photo"
          :seed="item.id"
          :size="30"
          class="mr-2"
        />
        <v-avatar v-else size="35" class="mr-2">
          <v-icon large>mdi-account-question</v-icon>
        </v-avatar>
        {{ item.name || "Conta não criada" }}
      </template>
      <template v-slot:[`item.membership.name`]="{ item }">
        <v-chip
          :color="item.membership.backgroundColor"
          text-color="white"
          label
          small
          dark
          :style="{
            background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${
              item.membership.backgroundImg
            }), ${item.membership.backgroundColor || '#3333'}80`,
            backgroundSize: 'cover',
            border: `1px solid ${item.membership.backgroundColor || '#000'}`,
            boxShadow: `0 0 10px  ${
              item.membership.backgroundColor || 'rgba(0,0,0,.2)'
            }`,
          }"
        >
          {{ item.membership.name }}
        </v-chip>
      </template>
      <template v-slot:[`item.filiation`]="{ item }">
        <v-chip
          v-if="item.filiationStatus == 'active'"
          small
          label
          color="success"
        >
          Válido até {{ item.lastPurchase.validUntil | date }}
        </v-chip>
        <v-chip
          v-else-if="item.filiationStatus == 'expired'"
          small
          label
          color="error"
        >
          Vencido em {{ item.lastPurchase.validUntil | date }}
        </v-chip>
        <v-chip
          v-else-if="item.filiationStatus == 'pending'"
          small
          label
          color="grey"
        >
          Pendente
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          icon
          @click="qrCodeModal(item)"
          :disabled="item.filiationStatus == 'pending'"
        >
          <v-icon>mdi-qrcode</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <membership-filiation ref="filiation" @success="getMembershipMembers" />
    <membership-card-modal ref="qr-card" />
  </div>
</template>

<script>
import MEMBERSHIP from "@/services/admin/membership";
import MembershipFiliation from "./modals/MembershipFiliation.vue";
import { mapActions, mapGetters } from "vuex";
import filiationStatus from "@/utils/filiationStatus";
import MembershipCardModal from "../../global/MembershipCardModal.vue";
export default {
  components: { MembershipFiliation, MembershipCardModal },
  data: () => ({
    loading: true,
    members: [],
    search: "",
    refreshDisabled: false,
    headers: [
      {
        text: "Nome",
        value: "name",
      },
      {
        text: "Telefone",
        value: "phone",
      },
      {
        text: "Plano",
        value: "membership.name",
      },
      {
        text: "Validade",
        value: "filiation",
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
      },
    ],
  }),
  methods: {
    ...mapActions("organization", ["updateMemberships"]),
    addFiliation() {
      this.$refs.filiation.open();
    },
    qrCodeModal(f) {
      const { membership, plan, filiation, lastPurchase, Filiations, ...user } =
        f;

      const data = {
        ...filiation,
        Membership: {
          ...membership,
          Organization: this.selectedOrganization,
        },
        User: user,
      };

      this.$refs["qr-card"].open(data);
    },
    enableRefresh(ms) {
      this.refreshDisabled = true;
      setTimeout(() => {
        this.refreshDisabled = false;
      }, ms);
    },
    async getMembershipMembers() {
      try {
        this.loading = true;
        this.refreshDisabled = true;
        const { users } = await MEMBERSHIP.filiation.getAll(
          this.selectedOrganization.id
        );
        this.members = users.map(this.mapMembership);
      } catch (e) {
        this.error =
          e.message || "Ocorreu um erro ao buscar os membros filiados.";
      } finally {
        this.loading = false;
        this.enableRefresh(10000);
      }
    },
    mapMembership(member) {
      const filiations = member.Filiations;
      const [filiation] = filiations.sort((a, b) => {
        return new Date(a.MembershipPurchase[0].createdAt) >
          new Date(b.MembershipPurchase[0].createdAt)
          ? 1
          : -1;
      });

      const fStatus = new filiationStatus(filiation);
      const lastPurchase = fStatus.lastPurchase;
      const membership = this.memberships.find(
        (m) => m.id == filiation.membershipId
      );
      const plan = membership.Plans.find((p) => p.id == lastPurchase.planId);
      return {
        ...member,
        filiation,
        membership,
        plan,
        filiationStatus: fStatus.status,
        lastPurchase,
      };
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization", "memberships"]),
    highlight() {
      var response = {
        withRegistration: 0,
        withoutRegistration: 0,
        nextValid: null,
      };

      this.members.forEach((member) => {
        if (!!member.name) response.withRegistration++;
        else response.withoutRegistration++;

        if (
          !response.nextValid ||
          new Date(response.nextValid) >
            new Date(member.lastPurchase.validUntil)
        ) {
          response.nextValid = member.lastPurchase.validUntil;
        }
      });
      return response;
    },
  },
  watch: {
    "selectedOrganization.id"() {
      this.getMembershipMembers();
    },
  },
  async mounted() {
    await this.updateMemberships();
    await this.getMembershipMembers();
  },
};
</script>
