<template>
  <div>
    <v-form @submit.stop v-model="valid">
      <v-text-field
        label="Título do campo"
        v-model="val.title"
        outlined
        dense
        :rules="[(v) => !!v || 'Campo obrigatório']"
        counter="30"
      />
      <v-text-field
        label="Descrição do campo"
        v-model="val.description"
        outlined
        dense
        hide-details
        counter="30"
      />
      <v-checkbox
        label="Campo obrigatório"
        v-model="val.required"
        dense
        hide-details
      />
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      val: this.value,
      valid: true,
    };
  },
  watch: {
    valid(val) {
      this.$emit("valid", val);
    },
    val: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        title: "",
        description: "",
        required: false,
      }),
    },
  },
};
</script>

<style></style>
