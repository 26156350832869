<template>
  <div>
    <v-tabs v-model="tab" grow>
      <v-tabs-slider></v-tabs-slider>

      <v-tab> Dados </v-tab>
      <v-tab> Pré-visualização </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="pa-6 pt-4">
      <v-tab-item>
        <v-form
          @submit.prevent="$emit('next')"
          class="d-flex flex-column gap-2 mt-2"
          v-model="valid"
        >
          <v-text-field
            :value="val.name"
            @input="setValue('name', $event)"
            label="Nome do plano"
            outlined
            dense
            counter="30"
            :rules="[
              (v) => !!v || 'Campo obrigatório',
              (v) => v.length <= 30 || 'Máximo de 30 caracteres',
            ]"
          />
          <label class="mb-0">Benefícios</label>
          <div class="d-flex flex-column gap-1">
            <div class="" v-for="(benefit, i) in benefits" :key="i">
              <v-text-field
                :value="benefit"
                @input="benefitSetValue(i, $event)"
                outlined
                dense
                :label="`Benefício ${i + 1}`"
                :rules="[(v) => !!v || !!i || 'Campo obrigatório']"
                prepend-inner-icon="mdi-star"
                :append-outer-icon="benefits.length > 1 ? 'mdi-delete' : ''"
                @click:append-outer="
                  benefits.length > 1 ? removeBenefits(i) : ''
                "
              />
            </div>
            <v-btn small color="primary" block @click="addBenefits">
              <v-icon small left>mdi-plus</v-icon>
              Adicionar benefício
            </v-btn>
          </div>
          <v-divider class="my-1" />

          <div class="d-flex flex-wrap gap-4">
            <div class="flex-shrink-0">
              <v-menu :close-on-content-click="false" top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex flex-column align-center">
                    <label class="mb-0" v-on="on">Cor de fundo</label>
                    <v-avatar
                      v-bind="attrs"
                      v-on="on"
                      :style="{ background: val.backgroundColor }"
                      class="white--text"
                    >
                      <v-icon x-large>mdi-palette</v-icon>
                    </v-avatar>
                  </div>
                </template>

                <v-color-picker
                  v-model="val.backgroundColor"
                  dot-size="25"
                  show-swatches
                  swatches-max-height="100"
                ></v-color-picker>
              </v-menu>
            </div>

            <file-drop
              v-if="!val.backgroundImg"
              @change="setBgImage"
              label="Imagem de fundo"
              padding="pa-8"
              accept="image/*"
              class="flex-grow-1"
            />
            <div
              v-else
              class="d-flex gap-4 pa-2 align-center flex-wrap rounded"
              style="border: 1px solid #ccc"
            >
              <div style="width: 75px; height: 75px">
                <v-img :src="val.backgroundImg" height="75px" class="rounded" />
              </div>
              <h6 class="mb-0">Foto de fundo</h6>
              <v-btn
                outlined
                small
                color="error"
                @click="removeBgImage"
                class="mt-2"
              >
                Remover imagem
              </v-btn>
            </div>
          </div>
        </v-form>
        <div class="d-flex justify-space-between align-center mt-4">
          <v-btn text @click="$emit('close')"> Cancelar </v-btn>
          <v-btn color="primary" @click="next" :disabled="!valid">
            Próximo
          </v-btn>
        </div>
      </v-tab-item>
      <v-tab-item>
        <membership-card :value="membershipCardValue" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import FileDrop from "../../../../global/FileDrop.vue";
import MembershipCard from "../../../../global/MembershipCard.vue";
const defaultMembership = () => ({
  name: "",
  description: "\n",
  backgroundColor: "#111827",
  backgroundImg: "",
});
export default {
  emits: ["next", "close", "input"],
  components: { FileDrop, MembershipCard },
  data: () => ({
    tab: 0,
    valid: false,
    backgroundFile: null,
  }),
  methods: {
    next() {
      this.emitInput(this.val);
      this.$emit("next");
    },
    emitInput(val) {
      this.$emit(
        "input",
        Object.assign({}, val, {
          description: this.val.description.trim(),
          backgroundFile: this.backgroundFile,
        })
      );
    },
    setValue(key, value) {
      this.val = { ...this.val, [key]: value };
    },
    benefitSetValue(index, value) {
      this.benefits = this.benefits.map((b, i) => (i === index ? value : b));
    },
    addBenefits() {
      this.benefits = [...this.benefits, ""];
    },
    removeBenefits(index) {
      this.benefits = this.benefits.filter((_, i) => i !== index);
    },
    removeBgImage() {
      this.backgroundFile = null;
      this.val.backgroundImg = "";
    },
    setBgImage(file) {
      this.backgroundFile = file;
      this.val.backgroundImg = URL.createObjectURL(file);
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    benefits: {
      get() {
        return this.val.description.split("\n");
      },
      set(val) {
        this.val.description = val.join("\n");
      },
    },
    val: {
      get() {
        return this.value;
      },
      set(val) {
        this.emitInput(val);
      },
    },
    membershipCardValue() {
      return {
        id: "id do plano",
        Membership: {
          ...this.val,
          Organization: { ...this.selectedOrganization },
        },
        User: {
          id: Math.random().toString(36),
          name: "Nome do usuário",
          document: "123.456.789-00",
        },
        MembershipPurchase: [
          {
            validUntil: moment().add(1, "year").toISOString(),
            Payment: { status: "succeeded" },
          },
        ],
      };
    },
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: defaultMembership,
    },
  },
};
</script>

<style></style>
