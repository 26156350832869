var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"500","persistent":_vm.loading},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-3"},[_c('v-form',[_c('div',{staticClass:"d-flex justify-center mb-4"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-avatar',{attrs:{"size":"100","color":"grey"}},[_c('v-img',{attrs:{"src":_vm.partner.image || _vm.fileUrl}},[_c('v-fade-transition',{attrs:{"leave-absolute":""}},[(hover || _vm.loading || (!_vm.partner.image && !_vm.fileUrl))?_c('v-avatar',{staticClass:"d-flex transition-fast-in-fast-out grey v-card--reveal text-h2 white--text justify-center align-center",staticStyle:{"opacity":"0.8"},attrs:{"size":"100"},on:{"click":_vm.changePhoto}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-camera")])],1):_vm._e()],1)],1)],1)]}}])}),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"photoInput",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onPhotoChange}})],1),_c('place-search',{attrs:{"outlined":"","label":"Pesquise pelo nome ou endereço do parceiro","placeholder":"Pesquise pelo nome para preencher automaticamente os campos","detailsFields":[
            'formatted_phone_number',
            'website',
            'business_status',
            'url',
            // 'photos',
          ]},on:{"input:raw":_vm.importDetails},model:{value:(_vm.partner.address),callback:function ($$v) {_vm.$set(_vm.partner, "address", $$v)},expression:"partner.address"}}),_c('v-divider',{staticClass:"my-4"}),_c('v-text-field',{attrs:{"label":"Nome","outlined":"","dense":"","rules":[
            (v) => !!v || 'Nome é obrigatório',
            (v) =>
              (v && v.length <= 50) || 'Nome deve ter menos de 50 caracteres',
          ]},model:{value:(_vm.partner.name),callback:function ($$v) {_vm.$set(_vm.partner, "name", $$v)},expression:"partner.name"}}),_c('v-text-field',{attrs:{"label":"Telefone","outlined":"","dense":""},model:{value:(_vm.partner.phone),callback:function ($$v) {_vm.$set(_vm.partner, "phone", $$v)},expression:"partner.phone"}}),_c('v-text-field',{attrs:{"label":"Site/Rede Social","outlined":"","dense":""},model:{value:(_vm.partner.website),callback:function ($$v) {_vm.$set(_vm.partner, "website", $$v)},expression:"partner.website"}}),_c('v-textarea',{attrs:{"label":"Descrição do benefício","outlined":"","dense":"","rules":[
            (v) => !!v || 'Descrição é obrigatória',
            (v) =>
              (v && v.length <= 500) ||
              'Descrição deve ter menos de 500 caracteres',
          ],"counter":"500","rows":"3"},model:{value:(_vm.partner.description),callback:function ($$v) {_vm.$set(_vm.partner, "description", $$v)},expression:"partner.description"}}),_c('v-autocomplete',{staticClass:"mb-3",attrs:{"outlined":"","dense":"","label":"Categoria","items":_vm.categoryList,"search-input":_vm.searchCategory,"auto-select-first":"","hide-details":""},on:{"update:searchInput":function($event){_vm.searchCategory=$event},"update:search-input":function($event){_vm.searchCategory=$event}},model:{value:(_vm.partner.category),callback:function ($$v) {_vm.$set(_vm.partner, "category", $$v)},expression:"partner.category"}})],1),(_vm.error)?_c('v-alert',{staticClass:"mt-4",attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":_vm.close}},[_vm._v(" Fechar ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.partner.id ? "Atualizar" : "Criar")+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }