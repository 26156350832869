<template>
  <div class="mt-2">
    <div class="d-flex gap-2">
      <v-text-field
        dense
        :disabled="loading"
        v-model="search"
        label="Pesquisar"
        outlined
        hide-details
      />
      <v-btn @click="share" :disabled="loading" icon v-if="canShare">
        <v-icon>mdi-share-variant</v-icon>
      </v-btn>

      <v-btn
        color="primary"
        @click="addPartner"
        :disabled="loading"
        :fab="$vuetify.breakpoint.smAndDown"
        :small="$vuetify.breakpoint.smAndDown"
      >
        <v-icon
          :small="$vuetify.breakpoint.mdAndUp"
          :left="$vuetify.breakpoint.mdAndUp"
        >
          mdi-plus
        </v-icon>
        <span v-if="$vuetify.breakpoint.mdAndUp">Parceiro</span>
      </v-btn>
    </div>
    <v-alert v-if="error" type="error" class="mb-4">
      {{ error }}
    </v-alert>
    <p class="mb-0 text-end" v-if="!!partners && partners.length">
      {{ partners.length }} parceiro{{ partners.length != 1 ? "s" : "" }}
    </p>

    <!-- Alert loading -->

    <v-alert
      v-if="loading && !partners"
      color="info"
      text
      class="mt-4 mb-0"
      dense
    >
      Carregando parceiros...
    </v-alert>
    <!-- Alert empty -->
    <v-alert
      v-if="!loading && !partners.length"
      color="info"
      class="mt-4 mb-0"
      text
      dense
    >
      <p class="text-18 font-weight-bold mb-0">
        Seu organização ainda não possui parceiros
      </p>
      <p class="mb-0">
        Os parceiros são exibidos na página de membros e perfil da sua
        organização
      </p>
    </v-alert>

    <cols-organizer
      :items="partnersFiltered"
      itemKey="id"
      cols="1"
      sm="2"
      md="3"
      lg="4"
      xl="5"
    >
      <template v-slot="{ item }">
        <PartnerCard
          :partner="item"
          @edit="editPartner(item)"
          @refresh="getPartners"
        />
      </template>
    </cols-organizer>
    <membership-partner-modal
      :partners="partners || []"
      ref="membershipPartnerModal"
      @success="getPartners"
    />
  </div>
</template>

<script>
import MEMBERSHIP from "@/services/admin/membership";
import { mapGetters } from "vuex";

import ColsOrganizer from "../../global/ColsOrganizer.vue";
import PartnerCard from "./PartnerCard.vue";
import MembershipPartnerModal from "./modals/MembershipPartner.vue";
import organization from "../../../store/modules/organization";

export default {
  components: {
    ColsOrganizer,
    PartnerCard,
    ColsOrganizer,
    MembershipPartnerModal,
  },
  data: () => ({
    partners: null,
    loading: false,
    error: null,
    search: "",
    canShare: !!navigator.share,
  }),
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),

    partnersFiltered() {
      if (!this.partners) return [];
      return this.partners.filter((promoter) => {
        return promoter.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  methods: {
    share() {
      navigator.share({
        title: `${this.selectedOrganization.name} - Parceiros`,
        text: "Conheça nossos parceiros",
        url: `${window.location.origin}/shop/${this.selectedOrganization.slug}#partners`,
      });
    },
    addPartner() {
      this.$refs.membershipPartnerModal.open();
    },
    editPartner(partner) {
      this.$refs.membershipPartnerModal.open(partner);
    },
    async getPartners() {
      try {
        this.loading = true;
        const { partners } = await MEMBERSHIP.partner.getAll(
          this.selectedOrganization.id
        );
        this.partners = partners;
        this.loading = false;
      } catch (error) {
        this.error = error.message || "Ocorreu um erro ao carregar os posts";
        this.loading = false;
      }
    },
  },
  watch: {
    "selectedOrganization.id"() {
      this.getPartners();
    },
  },
  mounted() {
    this.getPartners();
  },
};
</script>

<style></style>
