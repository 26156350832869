<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-stepper v-model="currStep">
        <v-stepper-header>
          <template v-for="(step, index) in steps">
            <v-stepper-step
              :key="index"
              :complete="currStep > index"
              :step="index + 1"
              :editable="!!membership.id || currStep > index"
            >
              {{ step.title }}
            </v-stepper-step>

            <v-divider
              v-if="index !== steps.length - 1"
              :key="`divider-${index}`"
            />
          </template>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content
            class="pa-0 h-full"
            v-for="(step, index) in steps"
            :key="`step-${index}`"
            :step="index + 1"
          >
            <component
              class="h-full"
              :is="step.component"
              :value="step.modelValue"
              @input="setValue(step.modelKey, $event)"
              :loading="loading"
              :membership="membership"
              :customFields="customFields"
              :plans="plans"
              @next="next"
              @prev="prev"
              @close="close"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import MembershipCreateStepInformation from "./components/MembershipCreateStepInformation.vue";
import MembershipCreateStepPlans from "./components/MembershipCreateStepPlans.vue";
import MembershipCreateStepForm from "./components/MembershipCreateStepForm.vue";
import MembershipCreateStepConfirmation from "./components/MembershipCreateStepConfirmation.vue";
const defaultMembership = () => ({
  name: "",
  description: "",
  backgroundColor: "#111827",
  backgroundImg: "",
});

export default {
  components: {
    MembershipCreateStepInformation,
    MembershipCreateStepPlans,
    MembershipCreateStepForm,
    MembershipCreateStepConfirmation,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      error: false,
      membership: defaultMembership(),
      plans: [],
      customFields: [],
      currStep: 1,
    };
  },
  methods: {
    next() {
      if (this.currStep < this.steps.length) this.currStep++;
      else this.save();
    },
    prev() {
      if (this.currStep > 1) this.currStep--;
    },
    setValue(key, value) {
      this[key] = value;
    },
    async save() {
      try {
        this.loading = true;
        this.error = false;

        this.loading = false;
        this.$emit("success", recall);
        this.close();
      } catch (e) {
        this.error = e.message || "Erro desconhecido";
        this.loading = false;
      }
    },
    open(membership) {
      this.currStep = 1;

      this.membership = Object.assign(defaultMembership(), membership, {
        Plans: undefined,
        customFields: undefined,
      });
      this.plans =
        membership?.Plans.map((p) => ({
          ...p,
          endDate: p.endDate ? moment(p.endDate).format("YYYY-MM-DD") : null,
        })) || [];
      this.customFields = membership?.customFields || [];
      this.dialog = true;
    },
    close(success = false) {
      if (this.loading) return;
      if (success) this.$emit("success");
      this.dialog = false;
    },
  },

  computed: {
    steps() {
      return [
        {
          title: "Informações",
          icon: "mdi-information",
          component: "MembershipCreateStepInformation",
          modelValue: this.membership,
          modelKey: "membership",
        },
        {
          title: "Planos",
          icon: "mdi-cash",
          component: "MembershipCreateStepPlans",
          modelValue: this.plans,
          modelKey: "plans",
        },
        // {
        //   title: "Campos personalizados",
        //   icon: "mdi-text-box-multiple",
        //   component: "MembershipCreateStepForm",
        //   modelValue: this.customFields,
        //   modelKey: "customFields",
        // },
        {
          title: "Confirmação",
          icon: "mdi-check",
          component: "MembershipCreateStepConfirmation",
        },
      ];
    },
  },
  filters: {},
};
</script>

<style></style>
