var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"pt-0"},[_c('v-alert',{attrs:{"type":"info","dense":"","text":""}},[_vm._v(" Os planos são as formas como você pode vender sua filiação. Você pode criar planos com diferentes durações e preços. ")]),(_vm.membership.id)?_c('v-alert',{attrs:{"type":"warning","dense":"","text":""}},[_vm._v(" Alterar um planos já existente não afetará a validade das filiações ativas. O novo preço e duração serão aplicados apenas nas próximas compras. ")]):_vm._e(),_c('v-form',{staticClass:"d-flex flex-column gap-2",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_vm._l((_vm.val),function(v,index){return _c('v-card',{key:index,staticClass:"rounded-lg pa-2 px-4",attrs:{"outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Título (opcional)","placeholder":`Plano ${_vm.$options.filters.membershipDuration(v)}`,"outlined":"","dense":"","hide-details":""},model:{value:(v.name),callback:function ($$v) {_vm.$set(v, "name", $$v)},expression:"v.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.validationType,"label":"Tipo de validade","outlined":"","dense":"","messages":[
                v.validationType == 'DATE'
                  ? 'Data fixa de vencimento'
                  : 'Data de vencimento a partir da compra',
              ]},model:{value:(v.validationType),callback:function ($$v) {_vm.$set(v, "validationType", $$v)},expression:"v.validationType"}}),(v.validationType == 'DATE')?[_c('v-text-field',{attrs:{"label":"Data de expiração","type":"date","rules":[(v) => !!v || 'Data obrigatória'],"outlined":"","dense":"","hide-details":"auto"},model:{value:(v.endDate),callback:function ($$v) {_vm.$set(v, "endDate", $$v)},expression:"v.endDate"}})]:_vm._e(),(v.validationType == 'PERIOD')?[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"flex-grow-0 flex-shrink-0",attrs:{"outlined":"","dense":"","type":"number","min":"1","rules":[
                    (v) => !!v || 'Duração é obrigatória',
                    (v) => v > 0 || 'Duração deve ser maior que 0',
                  ],"hide-details":"auto"},model:{value:(v.duration),callback:function ($$v) {_vm.$set(v, "duration", $$v)},expression:"v.duration"}}),_c('v-select',{staticClass:"ml-2 flex-grow-1",attrs:{"items":_vm.durationType[index],"outlined":"","dense":"","hide-details":""},model:{value:(v.durationType),callback:function ($$v) {_vm.$set(v, "durationType", $$v)},expression:"v.durationType"}})],1)]:_vm._e()],2),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex gap-2"},[_c('v-text-field',{attrs:{"rules":[
                  (va) => !!va || 'Preço é obrigatório',
                  (va) => va >= 3 || 'Preço deve ser maior que 3',
                ],"dense":"","prefix":"R$","label":"Preço","type":"number","step":"0.01","outlined":"","min":"3"},model:{value:(v.price),callback:function ($$v) {_vm.$set(v, "price", $$v)},expression:"v.price"}}),(!v.id)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removePlan(index)}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1),_c('v-checkbox',{attrs:{"label":"Mostrar Online","dense":"","hide-details":""},model:{value:(v.onlineSale),callback:function ($$v) {_vm.$set(v, "onlineSale", $$v)},expression:"v.onlineSale"}})],1)],1)],1)}),_c('v-btn',{attrs:{"small":!!_vm.val.length,"large":!_vm.val.length,"block":"","elevation":"0"},on:{"click":_vm.addPlan}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Adicionar plano ")],1)],2),_c('v-spacer'),_c('div',{staticClass:"d-flex justify-space-between align-center mt-3"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('prev')}}},[_vm._v(" Voltar ")]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.isValid || !_vm.valid},on:{"click":function($event){return _vm.$emit('next')}}},[_vm._v(" Próximo ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }