<template>
  <base-card class="pb-2 rounded-lg">
    <v-tabs v-model="tab" show-arrows class="rounded-t-lg">
      <v-tab v-for="tab in loading ? [] : tabsFiltered" class="rounded-t-lg" :key="tab.component">
        <v-icon left small>{{ tab.icon }}</v-icon>
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in loading ? [] : tabsFiltered" :key="item.name">
        <component :is="item.component" class="px-4 pb-4" />
      </v-tab-item>
    </v-tabs-items>
  </base-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MembershipsManagement from "@/components/admin/membership/MembershipsManagement.vue";
import MembershipsMembers from "@/components/admin/membership/MembershipsMembers.vue";
import MembershipsPartners from "@/components/admin/membership/MembershipsPartners.vue";

export default {
  metaInfo() {
    return { title: `Filiações` };
  },
  data: () => ({
    loading: true,
    tab: null,
    tabs: [
      {
        id: "membership",
        name: "Filiações",
        component: "MembershipsManagement",
        permission: 4194304,
        icon: "mdi-wallet-membership",
      },
      {
        id: "members",
        name: "Membros",
        component: "MembershipsMembers",
        permission: 8388608,
        icon: "mdi-card-account-details-star",
      },
      {
        id: "partners",
        name: "Parceiros",
        component: "MembershipsPartners",
        permission: 4194304,
        icon: "mdi-handshake",
      },
    ],
  }),
  methods: {
    ...mapActions("organization", ["updateMemberships"]),
    async getMembership() {
      try {
        this.loading = true;
        await this.updateMemberships();
        this.selectTab();
      } catch (e) {
        this.error =
          e.message || "Ocorreu um erro ao buscar os membros filiados.";
      } finally {
        this.loading = false;
      }
    },
    selectTab() {
      if (this.$route.hash) {
        let hash = this.$route.hash.replace("#", "");
        let index = this.tabsFiltered.findIndex((tab) => tab.id == hash);
        if (index) this.tab = index;
      }
    },
    vibrate() {
      if (navigator.vibrate) navigator.vibrate(50);
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    tabsFiltered() {
      return this.tabs.filter(
        (tab) => !tab.permission || this.hasPermission(tab.permission)
      );
    },
  },
  watch: {
    "selectedOrganization.id"() {
      this.getMembership();
    },
    "$route.hash"() {
      this.selectTab();
    },
    tab: {
      handler(val, oldVal) {
        if (val !== null && oldVal !== null) this.vibrate();
        let tab = this.tabsFiltered[val];
        if (tab) window.location.replace("#" + tab.id);
      },
      immediate: true,
    },
  },

  mounted() {
    this.getMembership();
  },
  components: {
    MembershipsMembers,
    MembershipsManagement,
    MembershipsPartners
  },
};
</script>

<style></style>
