<template>
  <v-card outlined class="pa-4"> </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        title: "",
        description: "",
        required: false,
        options: [],
      }),
    },
  },
};
</script>

<style></style>
